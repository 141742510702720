<template>
  <div>
    <div class="row">

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-start mb-5">
              <button
                type="button"
                :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisWeek"
                @click="Filter('thisWeek')"
              >
                Minggu Ini
              </button>
              <button
                type="button"
                :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisMonth"
                @click="Filter('thisMonth')"
              >
                Bulan Ini
              </button>
              <button
                type="button"
                :class="'btn btn-sm filter-btn mx-1 ' + this.period.lastMonth"
                @click="Filter('lastMonth')"
              >
                Bulan Kemarin
              </button>
              <button
                type="button"
                :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisYear"
                @click="Filter('thisYear')"
              >
                Tahun Ini
              </button>
              <!-- <button
              type="button"
              class="btn btn-outline-primary filter-btn mx-1"
              @click="periodFilter()"
            ></button> -->
          </div>
        </div>
      </div>

      <div class="col-md-9">
        <apexchart
          class="card-rounded-bottom"
          :options="chartOptions"
          :series="series"
          type="area"
          width="100%"
          height="300"
          v-if="chartLoad"
        ></apexchart>
      </div>

      <div class="col-md-3">
          <div class="row">
            <div class="col-md-2">
              <div
                class="text-center mr-2"
                style="
                  border-radius: 25px;
                  background-color: #f6d5e5;
                  width: 50px;
                "
              >
                <i
                  class="fas fa-tasks"
                  style="margin: 15px; font-size: 20px; color: #a0225f"
                ></i>
              </div>
            </div>
            <div class="col-md-10">
              <span class="d-block" style="font-size:14px;">Total Perjanjian :</span>
              <span class="d-block font-weight-bold" style="font-size:40px;">{{totalAppointment}}</span>
            </div>
          </div>  
      </div>  
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
export default {
  name: "widget-12",
  data() {
    return {
      chartOptions: {},
      series: [
        {
          name: "Jumlah :",
          data: [],
        },
      ],
      sum: "",
      arr: [],
      chartLoad: false,
      sumLoad: false,
      // filter
      filter: {
        start_date: "",
        end_date: "",
      },
      period: {
        thisWeek: "btn-purple",
        thisMonth: "btn-outline-purple",
        lastMonth: "btn-outline-purple",
        thisYear: "btn-outline-purple",
      },
      totalAppointment:0,
      
      tooltipPeriod: "thisWeek",

      // chart
      // series: [],
      chartOptions: {
        chart: {
          type: "area",
          
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['#245590'],
        },
        xaxis: {
          categories: [],
        },
        // yaxis: {
        //   title: {
        //     text: "$ (thousands)",
        //   },
        // },
        fill: {
          opacity: 1,
        },
        colors:['#F44336'],
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        title: {
            text: "Statistik Perjanjian",
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize:  '14px',
              fontWeight:  'bolder',
              // fontFamily:  undefined,
              // color:  '#263238'
            },
        }
      },
    };
  },
  methods: {
    Filter(tool) {
      this.tooltipPeriod = tool;
      this.chartLoad = false;

       if (tool == "thisWeek") {
        this.period.thisWeek = "btn-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "thisMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "lastMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "thisYear") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-purple";
      }

      this.getData();

      mapGetters(["layoutConfig"]);
    },
    // generalFilter() {
    //   this.tooltipPeriod = "custom";
    //   this.chartLoad = false;
    //   this.getData();
    //   mapGetters(["layoutConfig"]);
    // },
    // periodFilter() {
    //   this.tooltipPeriod = "custom";
    // },
    // resetFilter() {
    //   this.chartLoad = false;
    //   this.filter.start_date = "";
    //   this.filter.end_date = "";
    //   this.getData();
    //   mapGetters(["layoutConfig"]);
    // },

    month(month) {
      let monthName
      if(month == 1){
        monthName = "Jan"
        // monthName = "Januari"
      }else if(month == 2){
        monthName = "Feb"
        // monthName = "Februari"
      }else if(month == 3){
        monthName = "Mar"
        // monthName = "Maret"
      }else if(month == 4){
        monthName = "Apr"
        // monthName = "April"
      }else if(month == 5){
        monthName = "Mei"
        // monthName = "Mei"
      }else if(month == 6){
        monthName = "Jun"
        // monthName = "Juni"
      }else if(month == 7){
        monthName = "Jul"
        // monthName = "Juli"
      }else if(month == 8){
        monthName = "Agu"
        // monthName = "Agustus"
      }else if(month == 9){
        monthName = "Sep"
        // monthName = "September"
      }else if(month == 10){
        monthName = "Okt"
        // monthName = "Oktober"
      }else if(month == 11){
        monthName = "Nov"
        // monthName = "November"
      }else if(month == 12){
        monthName = "Des"
        // monthName = "Desember"
      }

      return monthName
    },

    async getData(tool) {
      window.arrData = [];
      let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
      let response = await module.get("graphic-medical-action", `${filterParams}`);
      
      let patient = [];
      let typePeriod = "Tanggal"

      this.totalAppointment = parseInt(response.total).toLocaleString('id-ID')
    
      if(this.tooltipPeriod != "thisYear" && this.tooltipPeriod != "thisWeek"){
        let b = 0
        let counter =1
        for (let a = 0 ;a <= response.days; a++) {
  
          if (typeof response.items[b] !== "undefined") {
            
            let monthName = this.month(response.items[b].month)

            if(response.items[b].day == counter){
              
              patient.push({
                ref_name: parseInt(response.items[b].day)+"-"+ monthName ,
                price: response.items[b].amount,
              });
              b++
            }else{
              patient.push({
                ref_name: parseInt(counter)+"-"+ monthName ,
                price: 0,
              });
              
              
            }
            
          }else{
            let month
            if(this.tooltipPeriod == "lastMonth"){
              month = moment().subtract(1,'month').format('MM')
            }else if(this.tooltipPeriod == "thisMonth"){
              month = moment().format('MM')
            }

            month = this.month(parseInt(month))
            patient.push({
                ref_name: parseInt(counter)+"-"+ month ,
                price: 0,
              });
              
              
          }
          counter++;
          
        }

        typePeriod = "Tanggal"
      }else if(this.tooltipPeriod == "thisYear" ){
        let b = 1
        for (let a = 0 ;a < 12; a++) {
          
          if(typeof response.items[a] !== "undefined"){

            let monthName
            if(response.items[a].month == 1){
              monthName = "Januari"
            }else if(response.items[a].month == 2){
              monthName = "Februari"
            }else if(response.items[a].month == 3){
              monthName = "Maret"
            }else if(response.items[a].month == 4){
              monthName = "April"
            }else if(response.items[a].month == 5){
              monthName = "Mei"
            }else if(response.items[a].month == 6){
              monthName = "Juni"
            }else if(response.items[a].month == 7){
              monthName = "Juli"
            }else if(response.items[a].month == 8){
              monthName = "Agustus"
            }else if(response.items[a].month == 9){
              monthName = "September"
            }else if(response.items[a].month == 10){
              monthName = "Oktober"
            }else if(response.items[a].month == 11){
              monthName = "November"
            }else if(response.items[a].month == 12){
              monthName = "Desember"
            }
  
            patient.push({
              ref_name: monthName ,
              price: response.items[a].amount,
            });
          }else{
            let monthName
            if(b == 1){
              monthName = "Januari"
            }else if(b == 2){
              monthName = "Februari"
            }else if(b == 3){
              monthName = "Maret"
            }else if(b == 4){
              monthName = "April"
            }else if(b == 5){
              monthName = "Mei"
            }else if(b == 6){
              monthName = "Juni"
            }else if(b == 7){
              monthName = "Juli"
            }else if(b == 8){
              monthName = "Agustus"
            }else if(b == 9){
              monthName = "September"
            }else if(b == 10){
              monthName = "Oktober"
            }else if(b == 11){
              monthName = "November"
            }else if(b == 12){
              monthName = "Desember"
            }
  
            patient.push({
              ref_name: monthName ,
              price: 0,
            });
          }

          b++
            
        }

        typePeriod = "Bulan"
      }else if(this.tooltipPeriod == "thisWeek" ){
        let b = 0
        let counter = response.start
      //   for (let a = 0 ;a <= response.days; a++) {
  
      //     let month
      //     let startMonth = moment().startOf('week').format('MM')
      //     let monthName = this.month(parseInt(startMonth))

      //     if (typeof response.items[b] !== "undefined") {

      //       if(response.items[b].day < response.start){
      //         month = moment().add(1,'months').format('MM')
      //         monthName = this.month(parseInt(month))
      //       }else if(counter < response.start){
      //         month = moment().add(1,'months').format('MM')
      //         monthName = this.month(parseInt(month))
      //       }
            
      //       if(response.items[b].day == counter){
              
      //         patient.push({
      //           ref_name: parseInt(response.items[b].day)+"-"+monthName ,
      //           price: response.items[b].amount,
      //         });
      //         b++
      //       }else{
      //         patient.push({
      //           ref_name: parseInt(counter)+"-"+monthName ,
      //           price: 0,
      //         });
              
              
      //       }
            
      //     }else{
      //       if(counter < response.start){
      //         month = moment().add(1,'months').format('MM')
      //         monthName = this.month(parseInt(month))
      //       }
      //       patient.push({
      //           ref_name: parseInt(counter)+"-"+monthName ,
      //           price: 0,
      //         });
              
              
      //     }
      //     counter++;
          
      //   } 

      //   typePeriod = "Tanggal"
      // }

      for (let a = 0; a <= response.days; a++) {
          let month;
          let startMonth = moment()
            .startOf("week")
            .format("MM");
          let monthName = this.month(parseInt(startMonth));

          if (typeof response.items[b] !== "undefined") {
              monthName = this.month(parseInt(response.items[b].month));

            patient.push({
              ref_name: parseInt(response.items[b].day) + "-" + monthName,
              price: response.items[b].amount,
            });
            b++
          } else {
            if (counter < response.start) {
              month = moment()
                .add(1, "months")
                .format("MM");
              monthName = this.month(parseInt(month));
            }
            patient.push({
              ref_name: parseInt(counter) + "-" + monthName,
              price: 0,
            });
          }
          counter++;
        }

        typePeriod = "Tanggal";
      }

      this.series[0].data = patient.map((patient) => parseFloat(patient.price));
      window.arrData = patient.map((patient) => String(patient.ref_name));
      this.chartLoad = true;

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: window.arrData,
            title: {
              text: 'Periode '+typePeriod,
            },
          },
        },
      };
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    this.getData();
    if (this.chartLoad == true) {
      
    }

    // this.$root.$on('filterChart',(tool)=>{
    //   this.Filter(tool)
    // })
    // reference; kt_stats_widget_12_chart
  },
};
</script>

