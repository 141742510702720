<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-alert
          show
          variant="light"
          class="alert alert-custom alert-white alert-shadow fade show gutter-b"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
            </span>
          </div>
          <div class="alert-text">
            Eksekutif Dashboard <b>Klinik IONA</b>
          </div>
        </b-alert>
      </div>
    </div>

    <!-- overview -->
    <div v-if="isOverview">
      <div class="row mb-1">
        <div class="col-md-6 d-flex align-self-center">
          <span style="font-size: 15px" class="mb-1 d-block"><b>OVERVIEW</b></span>
        </div>
        <!-- <div class="col-md-6 d-flex justify-content-end">
          <div class="btn" style="background-color: #a0225f; color: white">
            Tahun Ini
          </div>
        </div> -->
      </div>

      <!-- first row -->
      <div class="row mb-2">
        <div class="col-md-3">
          <div class="card" style="min-height: 110px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="
                    col-md-4
                    d-flex
                    align-self-center
                    justify-content-center
                  "
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 25px;
                      background-color: #f6d5e5;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-user"
                      style="margin: 15px; font-size: 20px; color: #a0225f"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">Pasien Terdaftar</span>
                  <h4 class="d-block mt-2">
                    {{ parseInt(items.allPatient).toLocaleString("id-ID") }}
                  </h4>
                </div>
              </div>
              <div class="row notes">
                <span class="text-notes font-weight-bolder">Semua Periode</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card" style="min-height: 110px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="
                    col-md-4
                    d-flex
                    align-self-center
                    justify-content-center
                  "
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 25px;
                      background-color: #f6d5e5;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-tasks"
                      style="margin: 15px; font-size: 20px; color: #a0225f"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">Jumlah Perjanjian</span>
                  <h4 class="d-block mt-2">
                    {{ parseInt(items.allAppointment).toLocaleString("id-ID") }}
                  </h4>
                </div>
              </div>

              <div class="row notes">
                <span class="text-notes font-weight-bolder">Semua Periode</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card" style="min-height: 110px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="
                    col-md-4
                    d-flex
                    align-self-center
                    justify-content-center
                  "
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 25px;
                      background-color: #f6d5e5;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-money-bill-wave"
                      style="margin: 15px; font-size: 20px; color: #a0225f"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">Jumlah Pendapatan</span>
                  <h4 class="d-block mt-2">
                    {{ parseInt(items.all_payment).toLocaleString("id-ID") }}
                  </h4>
                </div>
              </div>

              <div class="row notes">
                <span class="text-notes font-weight-bolder">Semua Periode</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card" style="min-height: 110px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="
                    col-md-4
                    d-flex
                    align-self-center
                    justify-content-center
                  "
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 25px;
                      background-color: #f6d5e5;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-shopping-basket"
                      style="margin: 15px; font-size: 20px; color: #a0225f"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">Jumlah Biaya Oprasional</span>
                  <h4 class="d-block mt-2">
                    {{ parseInt(items.all_expense).toLocaleString("id-ID") }}
                  </h4>
                </div>
              </div>
              <div class="row notes">
                <span class="text-notes font-weight-bolder">Semua Periode</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- second row -->
      <div class="row mb-2">
        <div class="col-md-3">
          <div class="card" style="min-height: 110px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="
                    col-md-4
                    d-flex
                    align-self-center
                    justify-content-center
                  "
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 25px;
                      background-color: #f6d5e5;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-hand-holding-usd"
                      style="margin: 15px; font-size: 20px; color: #a0225f"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">AVG Pendapatan</span>
                  <h4 class="d-block mt-2">
                    {{
                      parseInt(
                        items.payment_clinict / items.current_month
                      ).toLocaleString("id-ID")
                    }}/Bln
                  </h4>
                </div>
              </div>
              <div class="row notes">
                <span class="text-notes font-weight-bolder">Tahun Ini</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card" style="min-height: 110px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="
                    col-md-4
                    d-flex
                    align-self-center
                    justify-content-center
                  "
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 25px;
                      background-color: #f6d5e5;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-receipt"
                      style="margin: 15px; font-size: 20px; color: #a0225f"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">AVG Pengeluaran</span>
                  <h4 class="d-block mt-2">
                    {{
                      parseInt(
                        items.expense / items.current_month
                      ).toLocaleString("id-ID")
                    }}/Bln
                  </h4>
                </div>
              </div>
              <div class="row notes">
                <span class="text-notes font-weight-bolder">Tahun Ini</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card" style="min-height: 110px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="
                    col-md-4
                    d-flex
                    align-self-center
                    justify-content-center
                  "
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 25px;
                      background-color: #f6d5e5;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-hospital-user"
                      style="margin: 15px; font-size: 20px; color: #a0225f"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">AVG Pasien/Dokter</span>
                  <h4
                    class="d-block mt-2"
                    v-if="
                      items.appointment != 0 || items.doctorHasAppointment != 0
                    "
                  >
                    {{
                      parseInt(
                        items.appointment /
                          items.doctorHasAppointment /
                          items.current_month
                      ).toLocaleString("id-ID")
                    }}/Bln
                  </h4>
                  <h4
                    class="d-block mt-2"
                    v-if="
                      items.appointment == 0 && items.doctorHasAppointment == 0
                    "
                  >
                    0/Bln
                  </h4>
                </div>
              </div>
              <div class="row notes">
                <span class="text-notes font-weight-bolder">Tahun Ini</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card" style="min-height: 110px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="
                    col-md-4
                    d-flex
                    align-self-center
                    justify-content-center
                  "
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 25px;
                      background-color: #f6d5e5;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-notes-medical"
                      style="margin: 15px; font-size: 20px; color: #a0225f"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">AVG Perjanjian/Dokter</span>
                  <h4
                    class="d-block mt-2"
                    v-if="
                      items.appointment != 0 || items.doctorHasAppointment != 0
                    "
                  >
                    {{
                      parseInt(
                        items.appointment /
                          items.doctorHasAppointment /
                          items.current_month
                      ).toLocaleString("id-ID")
                    }}/Bln
                  </h4>
                  <h4
                    class="d-block mt-2"
                    v-if="
                      items.appointment == 0 && items.doctorHasAppointment == 0
                    "
                  >
                    0/Bln
                  </h4>
                </div>
              </div>
              <div class="row notes">
                <span class="text-notes font-weight-bolder">Tahun Ini</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- third row -->
      <div class="row mb-2">
        <div class="col-md-3">
          <div class="card" style="min-height: 110px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="
                    col-md-4
                    d-flex
                    align-self-center
                    justify-content-center
                  "
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 25px;
                      background-color: #f6d5e5;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-pills"
                      style="margin: 15px; font-size: 20px; color: #a0225f"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">AVG Pendapatan Apotek</span>
                  <h4 class="d-block mt-2">
                    {{
                      parseInt(
                        items.payment_pharmacy / items.current_month
                      ).toLocaleString("id-ID")
                    }}/Bln
                  </h4>
                </div>
              </div>
              <div class="row notes">
                <span class="text-notes font-weight-bolder">Tahun Ini</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card" style="min-height: 110px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="
                    col-md-4
                    d-flex
                    align-self-center
                    justify-content-center
                  "
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 25px;
                      background-color: #f6d5e5;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-cart-plus"
                      style="margin: 15px; font-size: 20px; color: #a0225f"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">AVG Pengeluaran Apotek</span>
                  <h4 class="d-block mt-2">
                    {{
                      parseInt(
                        items.expense_pharmacy / items.current_month
                      ).toLocaleString("id-ID")
                    }}/Bln
                  </h4>
                </div>
              </div>
              <div class="row notes">
                <span class="text-notes font-weight-bolder">Tahun Ini</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card" style="min-height: 110px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="
                    col-md-4
                    d-flex
                    align-self-center
                    justify-content-center
                  "
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 25px;
                      background-color: #f6d5e5;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-mortar-pestle"
                      style="margin: 15px; font-size: 20px; color: #a0225f"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">AVG Obat Terjual</span>
                  <h4 class="d-block mt-2">
                    {{
                      parseInt(
                        items.count_pharmacy / items.current_month
                      ).toLocaleString("id-ID")
                    }}/Bln
                  </h4>
                </div>
              </div>
              <div class="row notes">
                <span class="text-notes font-weight-bolder">Tahun Ini</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card" style="min-height: 110px">
            <div class="card-body p-4 text-center">
              <span class="text-teal"></span>
              <!-- <h4 class="d-block mt-2">{{ parseInt(items.appointment/items.doctorHasAppointment).toLocaleString('id-ID') }}</h4> -->
            </div>
          </div>
        </div>
      </div>

      <!-- fourth row -->
      <div class="row mb-2">
        <div class="col-md-3">
          <div class="card" style="min-height: 110px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="
                    col-md-4
                    d-flex
                    align-self-center
                    justify-content-center
                  "
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 25px;
                      background-color: #f6d5e5;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-user-md"
                      style="margin: 15px; font-size: 20px; color: #a0225f"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">Jumlah Dokter Aktif</span>
                  <h4 class="d-block mt-2">
                    {{
                      parseInt(items.doctorHasAppointment).toLocaleString(
                        "id-ID"
                      )
                    }}
                  </h4>
                </div>
              </div>
              <div class="row notes">
                <span class="text-notes font-weight-bolder">Saat Ini</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card" style="min-height: 110px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="
                    col-md-4
                    d-flex
                    align-self-center
                    justify-content-center
                  "
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 25px;
                      background-color: #f6d5e5;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-user-nurse"
                      style="margin: 15px; font-size: 20px; color: #a0225f"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">Jumlah Perawat Aktif</span>
                  <h4 class="d-block mt-2">
                    {{ parseInt(items.nurse).toLocaleString("id-ID") }}
                  </h4>
                </div>
              </div>
              <div class="row notes">
                <span class="text-notes font-weight-bolder">Saat Ini</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card" style="min-height: 110px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="
                    col-md-4
                    d-flex
                    align-self-center
                    justify-content-center
                  "
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 25px;
                      background-color: #f6d5e5;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-users"
                      style="margin: 15px; font-size: 20px; color: #a0225f"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">Jumlah Staff Aktif</span>
                  <h4 class="d-block mt-2">
                    {{ parseInt(items.staff).toLocaleString("id-ID") }}
                  </h4>
                </div>
              </div>
              <div class="row notes">
                <span class="text-notes font-weight-bolder">Saat Ini</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card" style="min-height: 110px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="
                    col-md-4
                    d-flex
                    align-self-center
                    justify-content-center
                  "
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 25px;
                      background-color: #f6d5e5;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-heartbeat"
                      style="margin: 15px; font-size: 20px; color: #a0225f"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">Jumlah Layanan Aktif</span>
                  <h4 class="d-block mt-2">
                    {{ parseInt(items.service).toLocaleString("id-ID") }}
                  </h4>
                </div>
              </div>
              <div class="row notes">
                <span class="text-notes font-weight-bolder">Saat Ini</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end overview -->

    <div class="my-2">
      <div class="row">
        <div class="col-md-3">
          <span style="font-size: 15px" class="mb-2"
            ><b>STATISTIK PASIEN</b></span
          >
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body p-4 text-center">
              <PatientChart></PatientChart>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-3">
        <div class="card">
          <div class="card-body p-4 text-center text-light" style="background-color:#a0225f">
            <span class="d-block">Total Pasien Terdaftar</span>
            <h3 class="d-block mt-2">{{ parseInt(items.patient).toLocaleString('id-ID') }}</h3>
          </div>
        </div>
      </div> -->
      </div>

      <div class="row mb-2">
        <!-- patient by age -->
        <div class="col-md-8">
          <div class="card">
            <div class="card-body p-4 text-center">
              <PatientAgeChart></PatientAgeChart>
            </div>
          </div>
        </div>

        <!-- patient by gender -->
        <div class="col-md-4">
          <div class="card" style="min-height: 410px">
            <div class="card-body p-4 text-center">
              <PatientGenderChart></PatientGenderChart>
            </div>
          </div>
        </div>
      </div>

      <div class="row ">
        <div class="col-md-12 my-2">
          <span style="font-size: 15px" class="mb-2"
            ><b>STATISTIK PERJANJIAN & TINDAKAN MEDIS</b></span
          >
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body p-4 text-center">
              <MedicalActionChart></MedicalActionChart>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-3">
        <div class="card">
          <div class="card-body p-4 text-center text-light" style="background-color:#a0225f">
            <span class="d-block">Total Pasien Terdaftar</span>
            <h3 class="d-block mt-2">{{ parseInt(items.patient).toLocaleString('id-ID') }}</h3>
          </div>
        </div>
      </div> -->
      </div>

      <div class="row mb-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body p-4 text-center">
              <ServiceChart></ServiceChart>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 my-2">
          <span style="font-size: 15px" class="mb-2"
            ><b>STATISTIK PEMASUKKAN & PENGELUARAN</b></span
          >
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body p-4 text-center">
              <MixedChart></MixedChart>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body p-4 text-center">
              <MixedPharmacyChart></MixedPharmacyChart>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 my-2">
          <span style="font-size: 15px" class="mb-2"
            ><b>STATISTIK DOKTER AKTIF</b></span
          >
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body p-4">
              <DoctorStatistic></DoctorStatistic>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";

import PatientChart from "@/component/report-charts/PatientChart.vue";
import MedicalActionChart from "@/component/report-charts/MedicalActionChart.vue";
import PatientAgeChart from "@/component/report-charts/PatientAgeChart.vue";
import PatientGenderChart from "@/component/report-charts/PatientGenderChart.vue";
import ServiceChart from "@/component/report-charts/ServiceChart.vue";
import MixedChart from "@/component/report-charts/MixedChart.vue";
import MixedPharmacyChart from "@/component/report-charts/MixedPharmacyChart.vue";
import DoctorStatistic from "@/component/report-charts/DoctorStatistic.vue";

export default {
  data() {
    return {
      dashboardAccess: false,
      items: [],
      isOverview: false,

      period: {
        thisWeek: "btn-purple",
        thisMonth: "btn-outline-purple",
        lastMonth: "btn-outline-purple",
        thisYear: "btn-outline-purple",
      },
    };
  },

  name: "dashboard",

  components: {
    PatientChart,
    MedicalActionChart,
    PatientAgeChart,
    PatientGenderChart,
    ServiceChart,
    MixedChart,
    MixedPharmacyChart,
    DoctorStatistic,
  },

  methods: {
    async pagination() {
      let response = await module.get("overview-dashboard");

      this.isOverview = true;
      this.items = response;
    },

    // Filter(tool) {
    //   if (tool == "thisWeek") {
    //     this.period.thisWeek = "btn-purple";
    //     this.period.thisMonth = "btn-outline-purple";
    //     this.period.lastMonth = "btn-outline-purple";
    //     this.period.thisYear = "btn-outline-purple";
    //   } else if (tool == "thisMonth") {
    //     this.period.thisWeek = "btn-outline-purple";
    //     this.period.thisMonth = "btn-purple";
    //     this.period.lastMonth = "btn-outline-purple";
    //     this.period.thisYear = "btn-outline-purple";
    //   } else if (tool == "lastMonth") {
    //     this.period.thisWeek = "btn-outline-purple";
    //     this.period.thisMonth = "btn-outline-purple";
    //     this.period.lastMonth = "btn-purple";
    //     this.period.thisYear = "btn-outline-purple";
    //   } else if (tool == "thisYear") {
    //     this.period.thisWeek = "btn-outline-purple";
    //     this.period.thisMonth = "btn-outline-purple";
    //     this.period.lastMonth = "btn-outline-purple";
    //     this.period.thisYear = "btn-purple";
    //   }

    //   this.$root.$emit("filterChart", tool);
    // },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.pagination();
  },
};
</script>

<style>
.btn-outline-purple {
  border: 1px solid #551871;
  color: #551871;
}

.btn-outline-purple:hover {
  background-color: #551871;
  color: white;
}

.btn-purple,
.btn-purple:hover {
  background-color: #551871;
  color: white !important;
}

.text-notes{
  color:#bfc0c5 !important;
  font-size: 10px;

}

.notes{
  position: absolute;
  bottom: 14px;
  right: 20px;
}

/* .btn-purple:hover{
  background-color: #551871;
  color: white;
} */
</style>
